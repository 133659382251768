<template>
  <div class="aui-wrapper aui-page__login">
    <div class="aui-content__wrapper">
      <main class="aui-content">
        <div class="login-header">
          <h2 class="login-brand">{{ $t('brand.lg') }}</h2>
        </div>
        <div class="login-body">
          <h3 class="login-title">{{ $t('login.title') }}</h3>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="密码登录" name="first">
              <el-form
                :model="dataForm"
                :rules="dataRule"
                ref="dataForm"
                @keyup.enter.native="dataFormSubmitHandle()"
                status-icon
              >
                <!-- <el-form-item>
                  <el-select v-model="$i18n.locale" class="w-percent-100">
                    <el-option
                      v-for="(val, key) in i18nMessages"
                      :key="key"
                      :label="val._lang"
                      :value="key"
                    ></el-option>
                  </el-select>
                </el-form-item> -->
                <el-form-item prop="username">
                  <el-input
                    v-model="dataForm.username"
                    :placeholder="$t('login.username')"
                  >
                    <span slot="prefix" class="el-input__icon">
                      <svg class="icon-svg" aria-hidden="true">
                        <use xlink:href="#icon-user"></use>
                      </svg>
                    </span>
                  </el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input
                    v-model="dataForm.password"
                    type="password"
                    :placeholder="$t('login.password')"
                  >
                    <span slot="prefix" class="el-input__icon">
                      <svg class="icon-svg" aria-hidden="true">
                        <use xlink:href="#icon-lock"></use>
                      </svg>
                    </span>
                  </el-input>
                </el-form-item>
                <el-form-item prop="captcha">
                  <el-row :gutter="20">
                    <el-col :span="14">
                      <el-input
                        v-model="dataForm.captcha"
                        :placeholder="$t('login.captcha')"
                      >
                        <span slot="prefix" class="el-input__icon">
                          <svg class="icon-svg" aria-hidden="true">
                            <use xlink:href="#icon-safetycertificate"></use>
                          </svg>
                        </span>
                      </el-input>
                    </el-col>
                    <el-col :span="10" class="login-captcha">
                      <img :src="captchaPath" @click="getCaptcha()" />
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item>
                  <el-button
                    clickMusic="true"
                    type="primary"
                    @click="dataFormSubmitHandle()"
                    class="w-percent-100"
                    >{{ $t('login.title') }}</el-button
                  >
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="手机登录" name="second">
              <div>
                <el-form ref="loginForm" :model="loginForm" :rules="rules">
                  <el-form-item prop="phone">
                    <el-input v-model="loginForm.phone" placeholder="手机号">
                      <i slot="prefix" class="el-input__icon el-icon-phone"></i>
                    </el-input>
                  </el-form-item>
                  <!-- <el-form-item label="图形验证码" prop="imageCode">
                    <el-input v-model="loginForm.imageCode"></el-input>
                    <img
                      :src="imageCodeSrc"
                      @click="refreshImageCode"
                      alt="图形验证码"
                    />
                  </el-form-item> -->
                  <el-form-item prop="captcha" v-if="captchaFlag">
                    <el-row :gutter="20">
                      <el-col :span="14">
                        <el-input
                          v-model="loginForm.captcha"
                          placeholder="图形验证码"
                        >
                          <span slot="prefix" class="el-input__icon">
                            <svg class="icon-svg" aria-hidden="true">
                              <use xlink:href="#icon-safetycertificate"></use>
                            </svg>
                          </span>
                        </el-input>
                      </el-col>
                      <el-col :span="10" class="login-captcha">
                        <img :src="captchaPath" @click="getCaptcha()" />
                      </el-col>
                    </el-row>
                  </el-form-item>

                  <el-form-item prop="smsCode">
                    <el-row :gutter="20">
                      <el-col :span="14">
                        <el-input
                          v-model="loginForm.smsCode"
                          placeholder="短信验证码"
                        >
                          <span slot="prefix" class="el-input__icon">
                            <svg class="icon-svg" aria-hidden="true">
                              <use xlink:href="#icon-safetycertificate"></use>
                            </svg>
                          </span>
                        </el-input>
                      </el-col>
                      <el-col :span="10" class="login-captcha">
                        <el-button
                          @click="sendSmsCode"
                          :disabled="countdown > 0"
                        >
                          {{
                            countdown > 0
                              ? `${countdown}秒后重新发送`
                              : '获取验证码'
                          }}
                        </el-button>
                      </el-col>
                    </el-row>
                  </el-form-item>

                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="handleSubmit"
                      class="w-percent-100"
                      >登录</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="login-footer">
          <p>
            <a>{{ $t('login.copyright') }}</a
            >2023 © shanci.tech
          </p>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { messages } from '@/i18n';
import { getUUID } from '@/utils';

export default {
  data() {
    return {
      deviceId: '',
      activeName: 'first',
      i18nMessages: messages,
      captchaPath: '',
      captchaFlag: false,
      dataForm: {
        username: '',
        password: '',
        uuid: '',
        captcha: '',
      },

      loginForm: {
        phone: '',
        imageCode: '',
        smsCode: '',
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          // 可以添加更多的手机号验证规则
        ],
        // imageCode: [
        //   { required: true, message: '请输入图形验证码', trigger: 'blur' },
        //   // 可以添加更多的图形验证码验证规则
        // ],
        smsCode: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' },
          // 可以添加更多的短信验证码验证规则
        ],
      },
      countdown: 0, // 短信验证码倒计时
      imageCodeSrc: '', // 图形验证码地址
    };
  },
  computed: {
    dataRule() {
      return {
        username: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        captcha: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
      };
    },
  },
  created() {
    this.getCaptcha();
    // eslint-disable-next-line no-undef
    this.deviceId = new Fingerprint().get();
  },
  methods: {
    async handleClick(tab, event) {
      if (tab.name === 'second' && !this.captchaFlag) {
        this.testCaptchaFlag();
      }
    },

    async testCaptchaFlag() {
      const res = await this.$http.post('/login/verifycode/captchaFlag', {
        deviceId: this.deviceId,
      });
      this.captchaFlag = res.data.data.captchaFlag;
    },
    async sendSmsCode() {
      // 发送短信验证码逻辑
      // 在这里可以调用后端接口发送短信验证码，并开始倒计时
      // 假设这里使用 setTimeout 模拟异步操作

      if (this.loginForm.phone.length !== 11) {
        return this.$message.error('请输入正确手机号');
      }

      if (!this.captchaFlag) {
        await this.testCaptchaFlag();
      }

      if (this.captchaFlag && !this.loginForm.captcha) {
        this.$message.error('请输入图形验证码');
        return false;
      }

      // this.dataForm.uuid
      //    captcha: '',
      try {
        const res = await this.$http.post('/login/verifycode', {
          mobile: this.loginForm.phone,
          deviceId: this.deviceId,
          uuid: this.dataForm.uuid,
          captcha: this.loginForm.captcha,
        });
        const { code, msg } = res.data;
        console.log(2);
        if (code !== 0) {
          this.$message.error(msg);
          return;
        }
        this.countdown = 60;
        const timer = setInterval(() => {
          this.countdown--;
          if (this.countdown === 0) {
            clearInterval(timer);
          }
        }, 1000);
      } catch (error) {
        this.$message(error);
      }
    },
    refreshImageCode() {
      // 刷新图形验证码逻辑
      // 在这里可以调用后端接口获取新的图形验证码地址
      // 假设这里使用一个简单的随机数模拟新的图形验证码地址
      this.imageCodeSrc = `https://example.com/image-code?${Math.random()}`;
    },

    handleSubmit: debounce(
      function () {
        this.$refs['loginForm'].validate(async (valid) => {
          if (!valid) {
            return false;
          }

          const res = await this.$http.post('/mobileLogin', {
            mobile: this.loginForm.phone,
            deviceId: this.deviceId,
            verifyCode: this.loginForm.smsCode,
          });

          const { code, data, msg } = res.data;

          if (code !== 0) {
            this.$message.error(msg);
            return;
          }
          sessionStorage.setItem('token', data.token);
          this.$router.push({ name: 'home' });
        });
      },
      1000,
      { leading: true, trailing: false }
    ),

    // 获取验证码
    getCaptcha() {
      this.dataForm.uuid = getUUID();
      this.captchaPath = `${window.SITE_CONFIG['apiURL']}/captcha?uuid=${this.dataForm.uuid}`;
    },
    // 表单提交
    dataFormSubmitHandle: debounce(
      function () {
        this.$refs['dataForm'].validate((valid) => {
          if (!valid) {
            return false;
          }
          this.$http
            .post('/login', this.dataForm)
            .then(({ data: res }) => {
              if (res.code !== 0) {
                this.getCaptcha();
                return this.$message.error(res.msg);
              }
              sessionStorage.setItem('token', res.data.token);
              this.$router.push({ name: 'home' });
            })
            .catch(() => {});
        });
      },
      1000,
      { leading: true, trailing: false }
    ),
  },
};
</script>

<style lang="scss">
.aui-wrapper .el-input__prefix .el-input__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 30px;
  // height: 30px;
  // margin-right: 10px;
  // color: #999;
  // font-size: 16px;
  // font-weight: 400;
  // line-height: 1;
  // border-radius: 4px;
}
.login-form {
  max-width: 400px;
  margin: 0 auto;
}

.form-item {
  margin-bottom: 15px;

  .el-input {
    width: 100%;
  }

  .image-code {
    cursor: pointer;
    margin-left: 10px;
  }

  .sms-code-button {
    margin-left: 10px;
  }
}

.login-button {
  margin-top: 20px;
  width: 100%;
}
</style>
